import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,2,3,4,5,6,7,8,10,11,12];

export const dictionary = {
		"/": [~13],
		"/about-us": [14],
		"/admin": [15,[2]],
		"/admin/orders": [~16,[2]],
		"/admin/stats": [17,[2,3]],
		"/admin/templates": [18,[2]],
		"/admin/templates/new": [~25,[2]],
		"/admin/templates/[slug]": [19,[2,4]],
		"/admin/templates/[slug]/metadata": [~20,[2,4]],
		"/admin/templates/[slug]/pages": [~21,[2,4]],
		"/admin/templates/[slug]/pages/[page]": [~22,[2,4]],
		"/admin/templates/[slug]/publish": [~23,[2,4]],
		"/admin/templates/[slug]/settings": [~24,[2,4]],
		"/admin/users/[uid]/books/[slug]": [26,[2,5]],
		"/admin/users/[uid]/books/[slug]/printPreview": [27,[2,5]],
		"/books": [28,[6]],
		"/books/templates": [29,[6]],
		"/books/templates/new": [~35,[6]],
		"/books/templates/[slug]": [30,[6,7]],
		"/books/templates/[slug]/metadata": [~31,[6,7]],
		"/books/templates/[slug]/pages": [~32,[6,7]],
		"/books/templates/[slug]/pages/[page]": [~33,[6,7]],
		"/books/templates/[slug]/settings": [~34,[6,7]],
		"/build-a-book": [36],
		"/characters": [~37,[8]],
		"/characters/create": [~39,[8]],
		"/characters/[uid]": [~38,[8]],
		"/login": [~40],
		"/policies/cookies": [41,[9]],
		"/policies/privacy": [42,[9]],
		"/policies/terms": [43,[9]],
		"/settings": [44,[10]],
		"/settings/profile": [45,[10]],
		"/stories": [~46],
		"/stories/[slug]": [47,[11]],
		"/stories/[slug]/imagine": [48,[11]],
		"/stories/[slug]/pages/[pageUID]": [49,[11,12]],
		"/subscribe": [50]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';